@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@include color-mode(dark) {
   --bs-body-color:#fff;
   --bs-body-color-rgb:255, 255, 255;
  --bs-body-bg:#0F0326;
  --bs-secondary-bg: #212529;
  //--bs-secondary-bg-rgb: rgb(33, 37, 41);
   --bs-tertiary-bg:#191D32;
   --bs-tertiary-bg-rgb:rgb(25,29,50);
   --bs-card-bg:#212529 !important;
   --bs-card-color:#fff !important;
   .light-logo {
    display: none;
  }
  .dark-logo {
    display: inline-block;
  }

}

@include color-mode(light) {
  --bs-body-color-rgb:0, 0, 0;
  .light-logo {
    display: inline-block;
  }
  .dark-logo {
    display: none;
   
  }
 
}

@media screen and (min-width: 768px) {
  .filterBackground {
    background-color: #170539;
  }
  .mobileOnly {
    display:  none;
  }
}
@media screen and (max-width: 768px) {
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
}

  

.bg-body-tertiary {
 background-color: var(--bs-tertiary-bg) !important; 
}

.body-color {
  fill:var(--bs-body-color)
;}

// .bg-body-secondary {
//   background-color: var(--bs-secondary-bg) !important;
// }

input:checked {
  background-color: rgba(255,110,78, 1) !important;
  border-color: rgba(255,110,78, 1) !important;
}

.card {
  background-color: var(--bs-secondary-bg) !important;

}

.form-control {
  background-color: var(--bs-secondary-bg) !important;
}

// .btn-yellow {
//  background-color: rgba(255,110,78, 1);

//  color: #fff;
// }

.btn-yellow {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(255,110,78, 1);
  --bs-btn-border-color: rgba(255,110,78, 1);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff9178;
  --bs-btn-hover-border-color: #ff9178;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c24d32;
  --bs-btn-active-border-color: #007bff; //rgba(255,110,78, 1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.225);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #60788C;
  --bs-btn-disabled-border-color: #60788C;
}

.btn-yellow-tool {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(255,110,78, 0.5);
  --bs-btn-border-color: rgba(255,110,78, 0.5);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff9178;
  --bs-btn-hover-border-color: #ff9178;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(255,110,78, 1);
  --bs-btn-active-border-color:rgba(255,110,78, 1); //rgba(255,110,78, 1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.225);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #60788C;
  --bs-btn-disabled-border-color: #60788C;
  border: none;
}
.btn-yellow-opaq{
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(255,110,78, 0.75);
  --bs-btn-border-color: rgba(255,110,78, 0.75);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff9178;
  --bs-btn-hover-border-color: rgba(255,110,78, 0.75);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(255,110,78, 1);
  --bs-btn-active-border-color: rgba(255,110,78, 0.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #60788C;
  --bs-btn-disabled-border-color: #60788C;
 }

.btn-lightblue {
 background-color: #cae7ff;
 color: #fff;
}

.btn-black{
 background-color: #000;
 color: #fff;
}

body, html, #root {
  height: 100%;
}

/* .request-card {
  max-width: "50em";
  min_width: "60%";
} */